import React from "react";
import pending from "../../assets/Images/pg-pending.svg";
import {NavLink} from "react-router-dom";

const pgPending = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="hero-sec hero-h">
                        <div className="authbody">
                            <img src={pending} alt="img" className="pg-logo-w" />
                            <div className="payment-dec">
                                <p>Your transaction is pending.  <br/> As soon as we will get the success <br/> response the balance update.</p>
                            </div>
                            <NavLink to={"/home"} className="primarybtn d-inline-b">
                                Go To Home
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default pgPending;
